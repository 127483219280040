<template>
    <div class="partner-custom-form flights-form">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('forms.export')"
            :title="$t('navigations.exports')"
            @submitPressed="submitPressed"
        />
        <export-form :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import ExportForm from "./components/ExportForm.vue";

export default {
    components: {
        TitleBar,
        ExportForm,
    },
    data() {
        return {
            action: null,
        };
    },
    methods: {
        submitPressed() {
            this.action = "download";
        },
    },
};
</script>
